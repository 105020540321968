// NotFoundPage.js
import React from 'react';
import { Link } from 'react-router-dom';

const NotFoundPage = () => {
    return (
        <div>
            <h1>404 - Страница не найдена</h1>
            <p>Извините, но страница, которую вы ищете, не существует.</p>
            <Link to="/">
                <p>Вернуться на главную</p>
            </Link>
        </div>
    );
};

export default NotFoundPage;
